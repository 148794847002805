<template>
    <div class="profile_notes">
        <div class="title">
            <h3>E-mail
                <ul class="inboxSent">
                    <li>Inbox</li>
                    <li class="active">Sent</li>
                </ul>
            </h3>
            <div class="right_side">                
                <div class="search_panel mr-3">
                    <div class="form-field">
                        <input type="text" name="search" placeholder="Search...">
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <PageItem/>
                <button :class="deleteBox.length ? 'delete active' : 'delete'"><i class="fas fa-trash-alt"></i></button>
            </div>
        </div>            
        <ul class="card_listing">
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>Get Ready for Next 90 Days</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <label for="mail1" class="checkbox">
                        <input type="checkbox" id="mail1" value="mail1" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status delivered mb-2">Delivered</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>A Form is Due</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>
                    <label for="mail2" class="checkbox">
                        <input type="checkbox" id="mail2" value="mail2" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status not_delivered mb-2">Not Delivered</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>A Form is Due</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>                    
                    <label for="mail3" class="checkbox">
                        <input type="checkbox" id="mail3" value="mail3" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status opened mb-2">opened</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>A Form is Due</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>                    
                    <label for="mail4" class="checkbox">
                        <input type="checkbox" id="mail4" value="mail4" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status opened mb-2">opened</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>A Form is Due</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>                    
                    <label for="mail5" class="checkbox">
                        <input type="checkbox" id="mail5" value="mail5" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status opened mb-2">opened</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
            <li>
                <NotesCard v-bind:action="actionObj" :perform="editFunc">
                    <h4>A Form is Due</h4>
                    <p>Pellentesque nec neque a purus venenatis tempus. Vivamus venenatis consectetur tellus, ut rhoncus orci dictum et.</p>                    
                    <label for="mail6" class="checkbox">
                        <input type="checkbox" id="mail6" value="mail6" v-model="deleteBox" hidden>
                        <span><i class="fas fa-check"></i></span>
                    </label>
                    <div class="group-col-2">
                        <div class="col-item">
                            <h5>Status</h5>
                            <span class="status opened mb-2">opened</span>
                        </div>
                        <div class="col-item">
                            <h5>Sent on:</h5>
                            <span class="date">03/07/22 | 04:37 pm</span>
                        </div>
                    </div>
                </NotesCard>
            </li>
        </ul>
        <div v-if="editEmail" class="edit_modal">
            <div class="edit_section setting_wpr p-0">
                <div class="form_body">
                    <h2 class="title_header">Edit E-mail<button class="close_btn" @click="editEmail = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <EmailComp isRecipient/>
                        <div class="action_wpr p-0 mt-5">
                            <button class="btn cancel_btn" @click="editEmail = false">Cancel</button>
                            <button class="btn save_btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagination mt-2">
            <ul>
                <li>1</li>
                <li class="active">2</li>
                <li>3</li>
                <li>4</li>
                <li><i class="fas fa-ellipsis-h"></i></li>
            </ul>
        </div>
</template>

<script>
import PageItem from './PageItem.vue';
import NotesCard from './NotesCard.vue';
import EmailComp from './EmailComp.vue';
export default{
    name: 'ProfileEmail',
    components:{
        PageItem,
        NotesCard,
        EmailComp
    },
    data(){
        return{
            editEmail: false,
            deleteBox:[],
            actionObj: [
                {
                    icon: "fa-edit",
                    label: "Edit",
                },
                {
                    icon: "fa-reply",
                    label: "Resend",
                }
            ]
        }
    },
    methods:{
        editFunc(){
            this.editEmail = true;
        }
    }
}
</script>
